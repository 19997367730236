import { reactive, toRefs, getCurrentInstance } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import swal from 'sweetalert'

export const componentState = () => {
  const { proxy } = getCurrentInstance()
  const route = useRoute()
  const router = useRouter()
  const state = reactive({
    kind: '',
    contents: '',
    category: proxy.$ConstCode.REPORT_TYPE,
    files: [],
    userNm: route.query.userNm
  })

  const fnSave = async () => {
    if (!isValid()) return
    if (!confirm('신고 접수 하시겠습니까?')) return
    const request = {
      productNo: route.query.productNo,
      productType: route.query.type,
      kind: state.kind,
      contents: state.contents
    }
    const params = {
      request: new Blob([JSON.stringify(request)], {
        type: 'application/json'
      }),
      file: state.files
    }
    const res = await proxy.$CustomerSvc.postCustomerReport(params)
    if (res.resultCode === '0000') {
      let alert = function(msg, icon) {
        swal({
          title: '',
          text: msg,
          icon,
          className: 'sweet-size'
        })
      }
      alert(
        '접수 되었습니다.\n(접수내역은 마이페이지>고객센터를 통해 확인 가능합니다.)'
      )
      router.go(-1)
    } else {
      alert(res.resultMsg)
    }
  }

  const fnUpdate = files => {
    state.files = files
  }

  const fnRemove = files => {
    state.files = files
  }

  const isValid = () => {
    if (proxy.$Util.isEmpty(state.kind)) {
      alert('신고 사유를 선택해 주세요.')
      return false
    }
    if (proxy.$Util.isEmpty(state.contents)) {
      alert('신고 내용을 입력해 주세요.')
      return false
    }
    return true
  }

  return { ...toRefs(state), fnSave, fnUpdate, fnRemove }
}
