<template>
  <main class="main" role="main">
    <div class="page">
      <div class="inner">
        <div class="page_con">
          <div class="con_ttl center">
            <span>판매자 신고하기</span>
          </div>
          <div class="form_wrap">
            <div class="form">
              <ul class="form_ul">
                <li class="form_li">
                  <div class="form_ttl essen">판매자</div>
                  <div class="form_in">
                    <label class="input">
                      <label class="input">
                        <input
                          type="text"
                          v-model="userNm"
                          class="disabled"
                          disabled
                        />
                      </label>
                    </label>
                  </div>
                </li>
                <li class="form_li">
                  <div class="form_ttl essen">신고 사유</div>
                  <div class="form_in">
                    <label class="select">
                      <select v-model="kind">
                        <option :value="''">신고 사유를 선택해 주세요.</option>
                        <option
                          v-for="(item, index) in category"
                          :key="index"
                          :value="item.value"
                          >{{ item.name }}</option
                        >
                      </select>
                    </label>
                  </div>
                </li>
                <li class="form_li">
                  <div class="form_ttl essen">신고 내용</div>
                  <div class="form_in">
                    <label>
                      <textarea
                        placeholder="300자 이내로 입력해 주세요."
                        maxlength="300"
                        v-model="contents"
                      ></textarea>
                    </label>
                  </div>
                </li>
                <FileInputLi
                  :buttonTitle="'이미지 첨부'"
                  :maxCount="5"
                  :readOnly="files.length > 5"
                  :value="files"
                  :aspectRatio="NaN"
                  @update="fnUpdate($event)"
                  @remove="fnRemove($event)"
                />
              </ul>
            </div>
          </div>
          <div class="con_btn">
            <div class="btn_wrap">
              <button class="btn bo" @click="$router.go(-1)">취소</button>
              <button class="btn" @click="fnSave">접수</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { componentState } from './index.js'
import FileInputLi from '@/components/views/market/write/file-input-li'

export default {
  name: 'cs-report-write-index',
  components: {
    FileInputLi
  },
  setup() {
    return { ...componentState() }
  }
}
</script>

<style scoped></style>
